import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';

import SideNav from '../Components/SideNav';
import SubCategoryGrid from '../Components/SubCategoryGrid';
import styles from './CategoryPage.styles';
import Breadcrumb from '../../Breadcrumb';

const mapStateToProps = (state) => ({
  layout: state.ShopSettings.layout
});

const useStyles = makeStyles(theme => styles(theme));

const connector = connect(mapStateToProps, null);

function Category({ uri, slug, products, name, description, wpChildren, wpParent, subcategories }) {
  const classes = useStyles();

  return <>
    <Breadcrumb
      type="category"
      ancestors={wpParent && wpParent.node}
      current={{
        title: name,
        slug: slug,
        uri: uri
      }}
    />
    <Grid container spacing={3}>
      <Grid item md={4} lg={3}>
        <SideNav name={name} uri={uri} description={description} parent={wpParent} subcategories={subcategories} products={products} />
      </Grid>
      <Grid item md={8} lg={9}>
        <SubCategoryGrid subcategories={wpChildren} />
      </Grid>
    </Grid>
  </>
}

export default connector(Category);