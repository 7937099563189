export default (theme) => ({
  subCatGrid: {
    position: "relative",
    marginBottom: 32,
    [theme.breakpoints.up("md")]: {
      paddingTop: 62,
    },
    "& .link-item": {
      padding: 12,
      backgroundColor: "white",
      boxShadow: "0 6px 14px rgba(0,0,0,0.13)",
      display: "block",
      textDecoration: "none",
      height: "100%",
      color: theme.palette.primary.main,
      [theme.breakpoints.up("md")]: {
        padding: 24,
      },
      "& span": {
        padding: "12px 0",
        fontWeight: 700,
        letterSpacing: "0.008em",
        fontSize: 16,
        lineHeight: 2,
        display: "block",
        [theme.breakpoints.up("md")]: {
          fontSize: 18,
          padding: "12px 0 0 ",
        },
      },
    },
  },
});
