import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'gatsby';
import styles from './SubCategoryGrid.styles';
import Img from 'gatsby-image';

const useStyles = makeStyles(theme => styles(theme));

export default function SubcategoryGrid({ subcategories }) {

  const classes = useStyles();

  return <Grid className={classes.subCatGrid} container spacing={3}>
    {subcategories.nodes.map((subcategory, index) => {
      return <Grid item xs={6} sm={4} key={index}>
        <Link to={subcategory.uri} className="link-item">
          {subcategory.productCategory.categoryImage !== null &&
            <Img fluid={subcategory.productCategory.categoryImage.localFile.childImageSharp.fluid} />
          }
          <span>{subcategory.name}</span>
        </Link>
      </Grid>;
  })}</Grid>;
}